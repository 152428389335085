<template>
  <div class="app-container">
    <el-form
      :model="dataForm"
      ref="dataForm"
      label-width="120px"
    >
      <el-form-item label="热量缺口(男):" prop="ReLianNan">
        <el-input-number
            v-model="dataForm.ReLianNan"
            auto-complete="off"
            placeholder="请输入"
          ></el-input-number>
      </el-form-item>
      <el-form-item label="热量缺口(女):" prop="ReLianNv">
          <el-input-number
            v-model="dataForm.ReLianNv"
            auto-complete="off"
            placeholder="请输入"
          ></el-input-number>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="doSave">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { get, save } from "@/api/setting/config";
export default {
  data() {
    return {
      dataForm: {},
    };
  },
  methods: {
    doSave: function () {
      this.$confirm("确认提交吗？", "提示", {}).then(() => {
        let data = Object.assign({}, this.dataForm);
        save(data).then((response) => {
          if (response) {
            this.msg.successMsg("操作成功")
          }
        });
      });
    },
    //删除
    handleDelete: function (index, row) {
      this.$confirm("确认删除该记录吗?", "提示", {
        type: "warning",
      }).then(() => {
        let para = { key: row.Key };
        del(para).then((response) => {
          if (response) {
            this.msg.successMsg("操作成功");
            this.queryAll();
          }
        });
      });
    },
    handleSizeChange(val) {
      var pageSize = `${val}`;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.getUserList());
    },

    handleCurrentChange(val) {
      this.pageIndex = `${val}`;
      this.getUserList();
    },
  },
  mounted() {
    let param={};
    get(param).then(res=>{
      this.dataForm=res;
    })
  },
};
</script>
