var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: { model: _vm.dataForm, "label-width": "120px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "热量缺口(男):", prop: "ReLianNan" } },
            [
              _c("el-input-number", {
                attrs: { "auto-complete": "off", placeholder: "请输入" },
                model: {
                  value: _vm.dataForm.ReLianNan,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "ReLianNan", $$v)
                  },
                  expression: "dataForm.ReLianNan"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "热量缺口(女):", prop: "ReLianNv" } },
            [
              _c("el-input-number", {
                attrs: { "auto-complete": "off", placeholder: "请输入" },
                model: {
                  value: _vm.dataForm.ReLianNv,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "ReLianNv", $$v)
                  },
                  expression: "dataForm.ReLianNv"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.doSave } },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }