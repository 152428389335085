import request from '@/utils/request'


export function save(data) {
  return request({
    url: 'Config/save',
    method: 'post',
    data
  })
}

export function get(data) {
  return request({
    url: 'Config/get',
    method: 'post',
    data
  })
}
